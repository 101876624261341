.searchBox {
    width: 354px;
    height: 48px;
    flex-grow: 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 7px 14px;
    border-radius: 4px;

    border: solid 1px #eaeaea;
    background-color: #fafafa;
    

    .searchBoxInput {
        width: 100%;
        margin-left:8px;
        font-size: 16px;
        font-weight: 500;
        text-align: left;
        color: #000;
        background-color: #fafafa;
    }

    &:has(.searchBoxInput:focus) {
        border: solid 1px #dbdbdb;
        background-color: #fff;
     
        .searchBoxInput {
            background-color: #fff;
        }
    }
}

.fullWidth {
    width: 100%;
}
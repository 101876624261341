.button {
    cursor: pointer;
    &.disabled {
        opacity: 1;
        cursor: not-allowed !important;
    }
}

.blue {
    background-color: #0868f4;
    color: #fff;

    &.disabled {
        
        background-color: #aeaeb2;
        color : #ffffff;
    }
}

.grey {
    background-color: #f2f2f2;
    color : #636366;
}

.black {
    background-color: #1c1c1e;
    color: #fff;
}

.confirmButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
    padding: 7px 14px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: bold;
    line-height: 1.45;
    text-align: left;
}

.iconButton,
.statefulIconButton {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 0;
    padding: 10px;
    border-radius: 4px;
    border: solid 1px #eaeaea;
    background-color: #fafafa;
    position: relative;

    &.highlighted {
        border-color: #f3f7fe;
        background-color: #f3f7fe;
        .image { 
            position:relative;
        }
        .image::before {
            content : "";
            display : block;
            position:absolute;
            top:-4px;
            right : -4px;
            border-radius: 50%;
            width: 6px;
            height: 6px;
            background-color: #0868f4;
        }
    }

    .image {
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
        background-color: transparent;
        height: 100%;
        width: 100%;
    }
}
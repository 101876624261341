.paginationContainer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 8px;
  padding: 0;

  .pageButton {
    padding: 6px 14px 8px 15px;
    border-radius: 4px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    color: #3a3a3c;

    &.active {
      font-weight: 800;
      background-color: #f3f7fe;
      color: #0868f4;
    }
  }

  .arrow {
    img {
      width: 24px;
      height: 24px;
    }

    &:disabled {
      visibility: hidden;
    }
  }
}
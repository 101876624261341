.button {
    cursor: pointer;
    &.disabled {
        opacity: 1;
        cursor: not-allowed !important;
    }
}

.switchToggleButtons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    padding: 4px;
    border-radius: 4px;
    background-color: #f1f3f5;

    .label {
        padding: 6px 20px;
        border-radius: 4px;
        font-size: 16px;
        font-weight: 500;
        color: #aeaeb2;

        &.active {
            font-weight: bold;
            color: #1c1c1e;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.05), 0 1px 2px 0 rgba(0, 0, 0, 0.1);
            background-color: #fff;
        }
    }
}
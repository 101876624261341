.defaultSelectButton {
    display: flex;
    height: 40px;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 8px;
    padding: 0px 12px;
    border-radius: 4px;
    background-color: #fafafa;
    cursor: pointer;

    font-size: 15px;
    font-weight: 500;
    line-height: 1.45;
    text-align: left;
    color: #636366;

    img {
        width: 20px;
        height: 20px;
    }

    &.selected {
        background-color: #f3f7fe;
        color: #0868f4;
    }
}

.withPopover {
    position: relative;
}

.popover {
    z-index: 10;
}

.dropdownContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 4px 0;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    width: max-content;
    max-width: 375px;
    overflow: hidden;
    top : calc(100% + 8px);
    background-color: #fff;
    right: 0px;

    .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 10px 16px;
        background-color: #fff;
        font-size: 15px;
        font-weight: 500;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.45;
        letter-spacing: normal;
        text-align: left;
        color: #3a3a3c;
    }

}

.popoverChipContainer {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    padding: 0;
    border-radius: 8px;
    box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.1);
    position: absolute;
    min-width: 375px;
    overflow: hidden;
    top : calc(100% + 8px);
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 4px 10px 4px 20px;
        background-color: #fff;
        border-bottom: 1px solid #eaeaea;
        
        .label {
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            color: #1c1c1e;
        }

        .isMultipleLabel {
            font-size: 15px;
            font-weight: 500;
            line-height: 1.45;
            text-align: left;
            color: #aeaeb2;
            margin-left: 8px;
        }

        .close {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            cursor: pointer;
        }
    }

    .body {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: flex-start;
        flex-wrap: wrap;
        gap: 10px;
        padding: 20px;
        background-color: #fff;
    }

    .chip {
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 4px;
        padding: 8px 16px;
        border-radius: 999px;
        background-color: #fafafa;
        font-weight: 500;
        line-height: 1.45;
        text-align: left;
        color: #aeaeb2;
        &.selected {
            background-color: #f3f7fe;
            color: #0868f4;
        }
    }
}


.bottomSheetContainer {
    height: 100%;
    padding-bottom:20px;
    overflow: hidden;
    .header {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 20px 20px 16px;
        background-color: #fff;
        border-bottom: 1px solid #eaeaea;
        
        .label {
            font-size: 18px;
            font-weight: bold;
            text-align: left;
            color: #1c1c1e;
        }

        .isMultipleLabel {
            font-size: 15px;
            font-weight: 500;
            line-height: 1.45;
            text-align: left;
            color: #aeaeb2;
            margin-left: 8px;
        }

        .close {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 12px;
            cursor: pointer;
        }
    }
    
    .body {
        width: 100%;
        overflow-y: scroll;
    }

    .chip {
        display: flex;
        width: 100%;
        height: 54px;
        padding: 16px 20px;
        font-size: 16px;
        font-weight: 500;
        line-height: 1.38;
        letter-spacing: -0.41px;
        text-align: left;
        color: #1c1c1e;
        &.selected {
            font-weight: 600;
            color: #0868f4;
            background-color: #f3f7fe;
        }

        .iconContainer {
            margin-left: auto;
            .checkIcon {
                width: 24px;
                height: 24px;
            }
        }
        
    }
}
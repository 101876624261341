.layoutActive {
    pointer-events: all;
}

.fullLayout {
    width: 100%;

    &>div {
        margin: 0 auto;
        ;
        width: 100%;
    }
}

.sideLayout {
    width: 100%;
    max-width: 396px;
    margin-right: auto;
    pointer-events: none;
    position: relative;
    &>* {
        pointer-events: all;
    }
}

.sticky {
    position: sticky;
    left: 0px;
}

.fixedLayout {
    width: 100vw;
    margin-right: auto;
    left: 0px;
}



.backgroundWhite {
    background-color: #fff;
}


.body {
    height: 100%;
}


.rootFrame {
    display: flex;
    align-items: center;
    flex-direction: column;
    width: 100%;
    height: 100%;
}

.webScrollableFrame {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    position: relative;
    scrollbar-width: none;

    &::-webkit-scrollbar {
        display: none;
    }

    .scrollContainer {
        width: 100%;
        height: 100%;
        overflow: auto;
        scrollbar-width: none;
    }

    .scrollContainer::-webkit-scrollbar {
        display: none;
    }

    .scrollContent {
        height: 100%;
        width: 100%;
    }

    .scrollbarX {
        position: absolute;
        bottom: 2px;
        left: 0;
        height: 8px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        cursor: pointer;
        transition: transform 0.1s linear;
        pointer-events: all;
    }

    .scrollbarY {
        position: absolute;
        top: 0;
        right: 0;
        width: 8px;
        background: rgba(0, 0, 0, 0.3);
        border-radius: 4px;
        cursor: pointer;
        transition: transform 0.1s linear;
        pointer-events: all;
    }
}
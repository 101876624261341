.imageLabelTabContainer {

    .tab {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 8px;
        padding: 12px 16px;

        font-size: 18px;
        font-weight: 500;
        background-color: #fff;
        color: #aeaeb2;
        margin-bottom:4px;
        &.selected {
            font-weight: bold;
            color: #1c1c1e;
            background-color: #f3f7fe;
            margin-bottom:0px;
            border-bottom : 4px solid #0868f4;
        }

        img { 
            width: 24px;
            height: 24px;
        }
    }

}

.LabelTabContainer {

    .tab {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        gap: 0px;
        padding: 8px 0px;
        font-size: 16px;
        font-weight: 500;
        color: #aeaeb2;
        margin-bottom:4px;
        white-space: pre;
        width: 100%;
        &.selected {
            background-color: #f3f7fe;
            font-weight: bold;
            color: #0868f4;
            margin-bottom:0px;
            border-bottom : 4px solid #0868f4;
        }
    }

}

.normalTabContainer {
    .tab {
        height: 47px;
        font-size: 16px;
        font-weight: 500;
        color: #aeaeb2;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        &.selected {
            font-weight: bold;
            color: #1c1c1e;
            position: relative;
            &:after {
                content : "";
                position: absolute;
                left: 0px;
                bottom :0px;
                height:2px;
                width: 100%;
                background-color: #1c1c1e;
                
            }
        }
    }
}

.controlTabContainer {
    
}
.flexContainer {
    display: flex;
    align-items: center;
    width: 100%;
}

.vertical {
    flex-direction: column;
}

.horizontal {
    flex-direction: row;
}

.justifyContanerCenter {
    justify-content: center;
}

.justifyContanerSpaceBetween {
    justify-content: space-between;
}

.leftContent {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
}

.rightContent {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    margin-left: auto;
}

.expandingChild {
    flex-grow: 1; /* 남은 모든 공간을 차지 */
}
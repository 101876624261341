.estateManageContainer {
    // background-color: #fff;

    &.web {
        min-width: 1440px;
    }

    .titleMargin {
        margin-left: 19px;
    }

    .searchBoxMargin {
        margin-left: 58px;
    }

    .headerContainer {
        z-index: 100;
        flex-shrink: 0;
    }

    .topHeader {
        display: flex;
        padding: 10px 20px;
        border-bottom: 1px solid #eaeaea;
    }

    .bottomHeader {
        padding: 0px 20px;
        height: 72px;

        .registerSale {
            font-size: 15px;
            font-weight: 500;
            color: #fff;
            padding: 8px 14px;
        }

        .checkedListContorllerContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 12px;
            padding: 6px 10px 6px 20px;
            border-radius: 4px;
            box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.08);
            border: solid 1px #eaeaea;
            background-color: #fff;
            font-size: 20px;
            font-weight: 500;
            color: #1c1c1e;

            .accent {
                font-weight: 800;
            }

            .dividier {
                width: 2px;
                height: 20px;
                background-color: #eaeaea;
            }

            .controller {
                width: 44px;
                height: 44px;
                flex-shrink: 0;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                object-fit: contain;
                border-radius: 4px;
                position: relative;
                cursor: pointer;

                &:hover {
                    background-color: #f2f2f2;

                    .tooltip {
                        display: block
                    }
                }

                .tooltip {
                    display: none;
                    width: max-content;
                    padding: 8px 14px;
                    border-radius: 4px;
                    background-color: rgba(28, 28, 30, 0.9);
                    font-size: 15px;
                    font-weight: 600;
                    color: #fff;
                    position: absolute;
                    top: calc(100% + 7px);

                }

            }
        }
    }

    .mobileHeaderContainer {
        .topHeader {
            width: 100%;
            justify-content: space-between;

            .title {
                font-size: 16px;
                font-weight: 500;
                line-height: 1.63;
                text-align: center;
                color: #313131;
            }
        }

        .sectionContainer {
            padding: 0px 20px;
            width: 100%;

            &.overflow {
                overflow: auto;
            }
        }

        .searchBoxContainer {
            .searchBox {
                width: 100%;
            }
        }

        .tabContainer {
            border-bottom: 1px solid #eaeaea;
        }

        .mobileShortcutContainer {
            padding: 8px 20px;
            background-color: #f3f4f6;

            .keyword {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.45;
                color: #3a3a3c;
                .word {
                    font-weight: bold;
                    line-height: 1.45;
                    color: #0868f4;
                    margin-right: 4px;
                }

            }

            .total {
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                color: #1c1c1e;
            }

            .remain {
                font-size: 14px;
                font-weight: 500;
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 8px;
                margin: 3px 94.5px 9px 19.8px;
                padding: 8px 14px;
                border-radius: 999px;
                background-color: #1c1c1e;
                text-align: left;
                color: #636366;

                .accent {
                    color: #0868f4;
                }
            }
        }

        .mobileHeader {
            display: flex;
            flex-direction: row;
            overflow-x: auto;
            overflow-y: hidden;
            white-space: nowrap;
            width: 100%;

            &>* {
                flex: 0 0 auto;
            }
        }
    }

    .mobileRegisterSaleContainer {
        width: fit-content;
        position: fixed;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 1;

        .registerSale {
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            gap: 8px;
            padding: 8px 14px;
            border-radius: 999px;
            background-color: #1c1c1e;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.45;
            text-align: left;
            color: #fff;
        }
    }
}

.alignSelfEnd {
    align-self: flex-end;
}

.cursorPointer {
    cursor: pointer;
}

.bodyContainer {
    height: 100%;
}

.dataContainer {
    $default-color : #ffffff;    
    $default-hover-color : #F2F2F2;
    $checked-color : #F3F7FE;
    $checked-hover-color : #E6F0FE;
    $done-color : #E1E1E5;
    $done-hover-color : #D1D1D6;

    width: 100%;
    height: 100%;
    overflow: hidden;

    &.fullMaxSize {
        width: 100vw !important;
    }

    .tableContainer {
        position: relative;
        overflow: scroll;
        width: 100%;
        height: 100%;

        table {
            width: 100%;

            &:has(.noData) {
                height: 100%;
            }
        }

        table,
        tr,
        td,
        th {
            border: none;
            padding: 0;
            border-collapse: separate;
            border-spacing: 0;
            line-height: normal;
            overflow: visible;
            background: none;
            color: inherit;
            margin: 0;
            text-align: inherit;
            font-size: inherit;
            font-weight: normal;
        }


        th,
        td {

            text-align: left;
            white-space: nowrap;
            border-bottom: 1px solid #eaeaea;
        }

        th {
            padding: 0px 24px;
        }

        td {
            padding: 20px 20px;
            background-color: #fff;
            position: relative;
        }

        td:first-child,
        th:first-child {
            padding: 0px 15px !important;
        }
        
        td:last-child,
        th:last-child{
            padding: 0px 10px !important;
        }

        thead {
            &.stickyHeader {
                position: sticky;
                top: 0;
                z-index: 10;
                box-shadow: 0 2px 2px rgba(0, 0, 0, 0.1);
            }

            th {
                background-color: #fafafa;
                font-size: 16px;
                font-weight: bold;
                text-align: left;
                color: #1c1c1e;
                border-top: 1px solid #eaeaea;
                border-bottom: 1px solid #eaeaea;
            }
        }

        .fixedCell {
            position: sticky;
            z-index: 2;

            &:has(.active) {
                z-index: 100;
            }
        }

        .fixedCell:first-child {
            width: 66px;
            left: 0;
            box-shadow: 5px 3px 15px 0 rgba(0, 0, 0, 0.08);
            cursor: pointer;
        }

        .fixedCell:last-child {
            width: 66px;
            right: 0;
            box-shadow: -3px 3px 15px 0 rgba(0, 0, 0, 0.08);
        }

        tr:not(.notaction) {
            cursor: pointer;

            &.checkedItem {
                td {
                    background-color: $checked-color;
                }
            }

            &.doneItem {
                td {
                    background-color: $done-color !important;
                }
            }

            &:hover {
                &.checkedItem {
                    td {
                        background-color: $checked-hover-color;
                    }
                }
    
                &.doneItem {
                    td {
                        background-color: $done-hover-color !important;
                    }
                }

                td {
                    background-color: $default-hover-color;
                }
            }
        }

        .noData {
            font-size: 18px;
            font-weight: 500;
            line-height: 1.56;
            text-align: center;
            color: #1c1c1e;

            p {
                margin: 12px 0px 0px;
                &:not(:first-child) {
                    margin-top:8px;
                }
            }
        }
    }

    .tableItemStyles {
        .checkboxWrapper {
            cursor: pointer;
            // padding: 15px;
            width: 18px;
            height: 18px;
            padding : 0px;
            margin: 0px;

            .checkbox {
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: solid 1.2px #AEAEB2;
                background-color: #fafafa;
                margin: 0px;
                cursor: pointer;

                &.checked {
                    background-color: #0868f4;
                    position: relative;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -60%) rotate(45deg);
                        height: 10px;
                        width: 6px;
                        border-bottom: 2px solid #fff;
                        border-right: 2px solid #fff;
                    }
                }
            }

        }

        .typeCell {
            display: flex;
            flex-direction: column;
            gap: 4px;

            .typeItem {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                gap: 8px;
                padding: 2px 8px;
                border-radius: 4px;
                font-weight: 500;
                font-size: 16px;
                font-weight: 500;
                line-height: 1.45;
                text-align: left;
                width: fit-content;
            }

            .type {
                background-color: #edf4ff;
                color: #0868f4;
            }

            .state {
                font-size: 14px;
                font-weight: 600;
                line-height: 1.45;
                background-color: #f3f4f6;
                padding: 4px 8px;
                border-radius: 4px;
                &.ad {
                    background-color: #ea4848;
                    color :#ffffff;
                }
                &.release {
                    color: #0868f4;
                }

                &.hide {
                    color: #b24763;
                }

                &.done {
                    background-color: #d8dbe3;
                    color: #767f96;
                }
            }
        }

        .photoCell {
            width: 60px;
            height: 44.8px;
            flex-grow: 0;
            border-radius: 1.7px;
            background-size: 60px 44.8px;
            background-repeat: no-repeat;
        }

        .priceCell {
            font-weight: bold;
            text-align: left;
            line-height: 1.45;

            .dealName {
                font-size: 16px;
            }

            .price {
                font-size: 20px;
                color: #3a3a3c;
            }
        }

        .addressCell {
            line-height: 1.45;
            text-align: left;
            color: #3a3a3c;

            .buildingName {
                font-size: 16px;
                font-weight: 600;
            }

            .details {
                font-size: 14px;
            }
        }

        .dateCell {
            font-weight: 500;
            line-height: 1.45;

            .agoDate {
                font-size: 14px;
                font-weight: 500;
                line-height: 1.45;
                color: #3a3a3c;
                margin-bottom: 4px;
            }

            .shortDate {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 2px 8px;
                border-radius: 4px;
                background-color: #f7f7f7;
                font-size: 16px;
                color: #636366;
            }
        }

        .controlContainer {
            display: flex;
            flex-direction: row;
            gap: 8px;


            .toogleSwitchContainer {
                .toggleSwitch {
                    width: 140px;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 12px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    font-size: 14px;
                    font-weight: bold;
                    line-height: 1.45;
                    color: #636366;
                    background-color: #fafafa;
                    text-align: left;
                    margin: 0px;
                    position: relative;

                    &.disabled {
                        background-color: #eaeaea !important;
                        color : #aeaeb2 !important;
    
                        cursor:not-allowed;
                    }

                    .slider {
                        position: relative;
                        width: 42px;
                        height: 20px;
                        border-radius: 16px;
                        border: solid 1px #dee2e6;
                        background-color: rgba(134, 142, 150, 0.1);
                        transition: background 0.3s;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            border: solid 1px #fff;
                            background-color: #fff;
                            left: 3px;
                            bottom: 2.5px;
                            border-radius: 50%;
                            transition: transform 0.3s;
                        }
                    }


                }

                input[type="checkbox"] {
                    position: absolute;
                    width: 0;
                    height: 0;
                    opacity: 0;
                }

                input[type="checkbox"]:checked+.toggleSwitch {
                    background-color: #f3f7fe;
                    color: #0868f4;

                    .slider {
                        background: #0a57f5;

                        &::before {
                            transform: translateX(20px);
                        }
                    }
                }
            }
        }

        .additionContainer {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 10px;
            padding: 0;

            .additionButtonWrapper {
                position: relative;
                min-width: 129px;

                &:has(.active):after {
                    content: '';
                    display: block;
                    position: absolute;
                    top: 14px;
                    right: 12px;
                    width: 6px;
                    height: 6px;
                    background-color: #00589A;
                    border-radius: 9999px;

                }

                .additionButton {
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    gap: 10px;
                    padding: 10px 16px 10px 12px;
                    border-radius: 4px;
                    border: solid 1px #f2f2f2;
                    background-color: #fafafa;
                    width: 100%;

                    .label {
                        font-size: 15px;
                        font-weight: 500;
                        text-align: left;
                        color: #1c1c1e;
                    }

                    img {
                        width: 20px;
                        height: 20px;
                    }

                    &:hover {
                        border: solid 1px #eaeaea;
                        background-color: #f2f2f2;

                    }

                    &.active {
                        background-color: #f3f7fe;
                        border-color: #f3f7fe;

                        .label {
                            color: #00589a;
                        }

                        &:hover {
                            background-color: #e6eef5;
                            border-color: #e6eef5;
                        }
                    }
                }

                .additionButtonPopup {
                    cursor: default;
                    position: absolute;
                    top: calc(100% + 6px);
                    right: 0px;
                    width: 312px;
                    padding: 0 0 20px;
                    border-radius: 8px;
                    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
                    background-color: #fff;
                    z-index: 1;

                    .header {
                        padding-left: 20px;
                        font-size: 18px;
                        font-weight: bold;
                        text-align: left;
                        color: #1c1c1e;
                    }

                    .body {
                        padding: 12px 20px;
                    }

                    .reportBody {
                        padding: 20px;



                        .reportItem {
                            width: 100%;
                            height: 59px;
                            flex-grow: 0;
                            padding: 9px 10px 10px;
                            border-radius: 4px;
                            background-color: #eff5fe;

                            .reportImage {
                                width: 40px;
                                height: 40px;
                                border-radius: 4px;
                                border: solid 1px #d3e2ff;
                                background-color: #fff;
                            }
                        }
                    }
                }
            }


        }

        .manageCell {
            position: relative;

            .icon {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                object-fit: contain;
                border-radius: 4px;
                width: 48px;
                height: 48px;

                img {
                    width: 24px;
                    height: 24px;
                }
            }

            &.active {
                .icon {
                    background-color: #f3f7fe !important;
                }
            }

            &:hover {
                .icon {
                    background-color: #f2f2f2;
                    ;
                }
            }
        }
    }

    .listContainer {
        overflow-y: scroll;
        overflow-x: hidden;
        width: 100%;
        padding: 0px;
        overscroll-behavior: contain;
        -webkit-overflow-scrolling: touch;
        &:has(.popupContainer) {
            -webkit-overflow-scrolling: auto;
        }
        &.bottomPadding {
            padding-bottom:300px;
        }

        .listItem {
            padding: 16px 20px 0px;
            position: relative;

            &:after {
                content : '';
                display: block;
                margin-top:16px;
                height: 1px;
                width: 100%;
                background-color: #f2f2f2;
            }

            .backgroundOverlay {
                    position: fixed;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                    background-color: rgba(0, 0, 0, 0.7);
                    z-index: 10;              
            }

            .popupContainer {
                background-color: #fff;
                z-index: 11;
                position: absolute;
                top: calc(100% + 8px);
                right: 20px;


                &.isMobile {
                    position: fixed;
                    left:50%;
                    top : 50%;
                    right: none;
                    transform: translate(-50%, -50%);
                }
            }

            &:not(.notaction) {
                cursor: pointer;
    
                &.checkedItem {
                        background-color: $checked-color;
                }
    
                &.doneItem {
                        background-color: $done-color !important;
                }
    
                &:hover {
                    &.checkedItem {
                            background-color: $checked-hover-color;
                    }
        
                    &.doneItem {
                            background-color: $done-hover-color !important;
                    }
    
                    background-color: $default-hover-color;
                }
            }
        }

        .listNoData {
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        .noData {
            font-size: 20px;
            font-weight: 500;
            line-height: 1.4;
            text-align: center;
            color: #1c1c1e;

            .wrap {
                margin-top:12px;
            }

            .nText {
                font-size: 15px;
                font-weight: 500;
                line-height: 1.87;
                text-align: center;
                color: #1c1c1e;
            }
        }
    }

    .listItemStyles {
        .contentContainer {
            align-items: flex-start;
            min-height: 74px;
        }


        .checkboxWrapper {
            position: absolute;
            top  : 4px;
            left: 4px;

            .checkbox {
                width: 18px;
                height: 18px;
                border-radius: 4px;
                border: solid 1.2px #AEAEB2;
                background-color: #fafafa;
                margin: 0px;
                cursor: pointer;

                &.checked {
                    background-color: #0868f4;
                    position: relative;

                    &:after {
                        content: "";
                        display: block;
                        position: absolute;
                        left: 50%;
                        top: 50%;
                        transform: translate(-50%, -60%) rotate(45deg);
                        height: 10px;
                        width: 6px;
                        border-bottom: 2px solid #fff;
                        border-right: 2px solid #fff;
                    }
                }
            }
        }

        .typeCell {
            display: flex;
            flex-direction: row;
            gap: 4px;

            .typeItem {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 2px 6px;
                border-radius: 2px;
                font-weight: 500;
                width: fit-content;
                line-height: 1.45;
                font-size: 13px;
                gap: 8px;
            }

            .type {
                background-color: #edf4ff;
                color: #0868f4;
            }

            .state {
                background-color: #f3f4f6;

                &.ad {
                    background-color: #ea4848;
                    color :#ffffff;
                }
                &.release {
                    color: #0868f4;
                }

                &.hide {
                    color: #b24763;
                }

                &.done {
                    background-color: #d8dbe3;
                    color: #767f96;
                }
            }
        }

        .agoDate {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.45;
            color: #636366;
        }

        .photoCell {
            width: 61.7px;
            height: 74px;
            flex-grow: 0;
            border-radius: 4px;
            background-position: center;
            background-size: cover;
            background-repeat: no-repeat;

            &.blank {
                background-color: #f3f4f6;
            }
        }

        .buildingName {
            font-size: 14px;
            font-weight: 500;
            line-height: 1.45;
            text-align: left;
            color: #3a3a3c;
        }

        .priceCell {

            .dealName {
                display: inline-block;
                font-size: 16px;
                font-weight: bold;
            }

            .price {
                display: inline-block;
                margin-left: 4px;
                font-size: 16px;
                font-weight: bold;
                color: #3a3a3c;
            }
        }

        .controlContainer {
            display: flex;
            flex-direction: row;
            gap: 8px;
            width: 100%;

            .toogleSwitchContainer {
                all:unset;
                width: 100%;
                .toggleSwitch {
                    min-width: 136.5px;
                    height: 100%;
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: center;
                    padding: 8px 12px;
                    border-radius: 4px;
                    cursor: pointer;
                    transition: all 0.3s ease;
                    font-size: 15px;
                    font-weight: bold;
                    line-height: 1.45;
                    color: #636366;
                    background-color: #fafafa;
                    text-align: left;
                    margin: 0px;
                    position: relative;

                    &.disabled {
                        background-color: #eaeaea !important;
                        color : #aeaeb2 !important;
    
                        cursor:not-allowed;
                    }
                    .slider {
                        position: relative;
                        width: 34px;
                        height: 20px;
                        border-radius: 16px;
                        border: solid 1px #dee2e6;
                        background-color: rgba(134, 142, 150, 0.1);
                        transition: background 0.3s;

                        &::before {
                            content: "";
                            position: absolute;
                            width: 14px;
                            height: 14px;
                            border: solid 1px #fff;
                            background-color: #fff;
                            left: 3px;
                            bottom: 2.5px;
                            border-radius: 50%;
                            transition: transform 0.3s;
                        }
                    }


                }

                input[type="checkbox"] {
                    position: absolute;
                    width: 0;
                    height: 0;
                    opacity: 0;
                }

                input[type="checkbox"]:checked+.toggleSwitch {
                    background-color: #f3f7fe;
                    color: #0868f4;

                    .slider {
                        background: #0a57f5;

                        &::before {
                            transform: translateX(12px);
                        }
                    }
                }
            }

        }

        .manageCell {
            position: relative;

            .icon {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                object-fit: contain;
                border-radius: 4px;
                width: 46px;
                height: 46px;
                background-color: #fafafa;

                img {
                    width: 27.6px;
                    height: 27.6px;
                }
            }
            &.active {
                .icon {
                    background-color: #f3f7fe !important;
                }
            }

        }

        .popupContainer {
            position: absolute;
            top: calc(100% + 6px);
            right: 0px;
            width: 312px;
            padding: 0 0 20px;
            border-radius: 8px;
            box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
            background-color: #fff;
            z-index: 1;

            .header {
                padding-left: 20px;
                font-size: 18px;
                font-weight: bold;
                text-align: left;
                color: #1c1c1e;
            }


            .body {
                padding: 12px 20px;
            }

            .reportBody {
                padding: 20px;

                .reportItem {
                    width: 100%;
                    height: 59px;
                    flex-grow: 0;
                    padding: 9px 10px 10px;
                    border-radius: 4px;
                    background-color: #eff5fe;

                    .reportImage {
                        width: 40px;
                        height: 40px;
                        border-radius: 4px;
                        border: solid 1px #d3e2ff;
                        background-color: #fff;
                    }
                }
            }
        }
    }
    .paginationContainer {
        width: 100%;
        padding: 14px 0px;
        border: solid 1px #eaeaea;
        background-color: #fff;
    }

    .scrollBtnContainer {
        position: absolute;
        width: 72px;
        height: 100%;
        top : 0px;
        
        pointer-events: none;
        display: flex;
        justify-content: center;
        align-items: center;
        z-index: 1024;
        .scrollBtn {
            width: 48px;
            height: 48px;
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-items: center;
            gap: 6px;
            padding: 12px;
            border-radius: 4.8px;
            box-shadow: 0 4px 15px 0 rgba(0, 0, 0, 0.15);
            background-color: #fff;
            pointer-events: all;
            img {
                width: 24px;
                height: 24px;
                flex-grow: 0;
                object-fit: contain;
            }
        }
        &.left { 
            left : 68px;
            background-image: linear-gradient(to left, rgba(0, 0, 0, 0) 100%, rgba(0, 0, 0, 0.04) 0%);

        }
        &.right {
            right : 82px;
            background-image: linear-gradient(to right, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.04) 100%);
        }
    }

}

.checkedListConrollerFromBottom {
    position: fixed;
    bottom:0px;
    width: 100%;

    left: 0px;
    height: 139px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    gap: 12px;
    padding: 20px 20px 32px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    box-shadow: 0 -3px 15px 0 rgba(0, 0, 0, 0.08);
    border: solid 1px #eaeaea;
    background-color: #fff;
    z-index: 8000;

    &.max {
        max-width: 396px;
    }
    .checkedGuideContainer {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        padding: 0;
        .count {
            font-size: 16px;
            font-weight: 500;
            font-stretch: normal;
            font-style: normal;
            line-height: normal;
            letter-spacing: normal;
            text-align: left;
            color: #1c1c1e;
            .strong {
                font-weight: bold;
            }
        }

        img {
            width: 24px;
            height: 24px;
        }
    }

    .buttonContainer {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 12px;
        padding: 0;
        .button {
            width: 100%;
            height: 51px;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 0;
            border-radius: 4px;
            background-color: #fafafa;
            gap: 8px;
            font-size: 16px;
            font-weight: 500;
            line-height: 1.45;
            img {
                width: 20px;
                height: 20px;
            }
        }
    }
}


.checkedControlModal{
    width: 257px;
    padding-bottom: 20px;
    .header {
        display: flex;
        padding: 20px 20px 16px 20px;
        align-items: center;
        gap: 32px;
        align-self: stretch;

        .title {
            color: #1C1C1E;
            font-size: 18px;
            font-weight: 700;
            line-height: 22px;
            letter-spacing: -0.408px;
        }

        .close {
            width: 24px;
            height: 24px;
        }
    }

    .button {
        img {
            width: 20px;
            height: 20px;
        }
        display: flex;
        padding: 16px 20px;
        align-items: center;
        gap: 12px;
        align-self: stretch;
        color: #1C1C1E;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 22px;
        letter-spacing: -0.408px;
    }
}

.membershipAlert {
    pointer-events: all;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
    padding: 8px 20px;
    background-color: #ffebe9;
    width: 100%;
    .infoIcon { 
        height: 36px;
        width: 36px;
    }
    .text {
        font-size: 16px;
        font-weight: 500;
        line-height: 1.45;
        text-align: left;
        color: #3a3a3c;

        .accent {
            font-size: 16px;
            font-weight: bold;
            line-height: 1.45;
            color: #ff3826;
        }
    }

    .right {
        .img {
            height: 36px;
            cursor: pointer;
        }
        .close {
            cursor: pointer;
            height: 24px;
            width: 24px;
            
        }
    }
}


.mcDropDownContainer {
    font-family: NotoSansCJKkr;
    pointer-events: all;
    position: fixed;
    z-index: 1048;
    width: fit-content;
    min-width: 120px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: stretch;
    gap: 2px;
    padding: 4px 0;
    border-radius: 8px;
    box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.1);
    background-color: #fff;

    .item {
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        align-items: center;
        gap: 8px;
        padding: 10px 18px 10px 16px;
        background-color: #fff;
        font-size: 15px;
        font-weight: 500;
        text-align: left;
        color: #3a3a3c;
        cursor: pointer;

        &:hover {
            background-color: #f2f2f2;
        }

        .itemIcon {
            width: 20px;
            height: 20px;
        }
    }
}
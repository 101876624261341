$toast-colors: (
  info: rgba(8, 104, 244, 0.8),
  success: #28a745,
  error: rgba(200, 0, 0, 0.8),
  warning: #ffc107
);

.toast-wrapper {
  position: fixed;
  width: 100%;
  pointer-events: none;
  z-index: 99999;
}

.toast-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  z-index: inherit;
  pointer-events: auto;
  position: fixed;
  width: max-content;
  max-width: 90vw;
}

/* 위치 설정 */
.top-left {
  top: 20px;
  left: 20px;
  flex-direction: column-reverse;
}

.top-center {
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  flex-direction: column-reverse;
}

.top-right {
  top: 20px;
  right: 20px;
  flex-direction: column-reverse;
}

.bottom-left {
  bottom: 20px;
  left: 20px;
}

.bottom-center {
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
}

.bottom-right {
  bottom: 20px;
  right: 20px;
}

.toast {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 20px;
  border-radius: 4px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.45;
  text-align: left;
  color: #fff;
  min-height: 54px;
  transition: transform 0.3s ease-out, opacity 0.3s ease-out;
  gap: 10px;
  &-message {
    flex: 1;
  }

  @each $type, $color in $toast-colors {
    &-#{$type} {
      background-color: $color;
    }
  }

  /* 애니메이션 */
  &.top-animation {
    animation: slide-down 0.3s ease-out;
  }

  &.bottom-animation {
    animation: slide-up 0.3s ease-out;
  }
}

/* 애니메이션 효과 */
@keyframes slide-up {
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slide-down {
  from {
    opacity: 0;
    transform: translateY(-50px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

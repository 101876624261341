
.defaultInput {
    display: flex;
    flex-direction: column;
    gap : 4px;
    width: 100%;

    .label {
        cursor: auto;
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        color: #636366;
    }

    .input {
        all:unset;
        cursor:text;
        padding: 12px;
        border-radius: 4px;
        border: solid 1px #eaeaea;
        background-color: #fafafa;
        font-size: 15px;
        font-weight: 500;
        line-height: 1.45;
        text-align: left;
        color: #3a3a3c;

        width: 100%;
        display: block;
        box-sizing: border-box;

        &::placeholder {
            font-weight: normal;
            color: #aeaeb2;
        }
    }
    textarea {
        overflow-x: hidden !important; 
        overflow-y: auto  !important; 
        white-space: pre-wrap  !important; 
        word-wrap: break-word  !important; 
      }
}
$overlay-bg: rgba(0, 0, 0, 0.7);
$sheet-bg: #fff;
$shadow-color: rgba(0, 0, 0, 0.2);
$shadow-light: rgba(0, 0, 0, 0.1);
$animation-duration: 0.3s; // 애니메이션 속도 설정

.bottomSheetOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-bg;
    opacity: 0;
    visibility: hidden;
    transition: opacity $animation-duration ease-in-out;
    z-index: 1000;
    pointer-events: all;
    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.bottomSheet {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 396px;
    display: flex;
    flex-direction: column;
    background-color: $sheet-bg;
    z-index: 9999;
    overflow: hidden;
    transform: translateY(100%);
    transition: transform $animation-duration ease-out, opacity $animation-duration ease-out;
    border-radius: 20px 20px 0px 0px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
    max-height: 70vh;
    
    &.open {
        transform: translateY(0);
    }

    .bottomSheetContent {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}



.modalPopupOverlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $overlay-bg;
    opacity: 0;
    visibility: hidden;
    transition: opacity $animation-duration ease-in-out;
    z-index: 9998;
    pointer-events: all;
    &.show {
        opacity: 1;
        visibility: visible;
    }
}

.modalPopup {
    position: fixed;
    left: 50%;
    top : 50%;
    display: flex;
    flex-direction: column;
    background-color: $sheet-bg;
    z-index: 9999;
    overflow: hidden;
    transform: translate(-50%, -50%);
    border-radius: 8px;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.10), 0px 8px 16px 0px rgba(0, 0, 0, 0.20);
    transition: opacity $animation-duration ease-in-out;
    max-height: 70vh;
    pointer-events: all;
    opacity: 0;
    visibility: hidden;
    &.open {
        opacity: 1;
        visibility: visible;
    }
    .modalContent {
        display: flex;
        flex-direction: column;
        overflow: hidden;
    }
}
